@import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,700&subset=latin-ext');
.resultQuiz{
    width: 100%;
    background-image: url('../../../public/mbbg.png');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding: 40px 0;
    font-size: 14px;
    height: 100%;
    padding-top: 55%;
}

.slogan{
    padding: 0 40px;
}
.sloganRe{
    color: #C82240;
    font-weight: 900;
    font-size: 20px;
}
.message{
    padding: 0 40px;
}
.result{
    display: flex;
    display: -webkit-flex;
    justify-content: space-evenly;
}
.re{
    margin: 20px 30px;
}
.btnResult{
    display: flex;
    display: -webkit-flex;
    -webkit-flex-wrap: nowrap;
    justify-content: center;
    flex-wrap: nowrap;
    padding: 0 20px;
}
.btn{
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    width: 80%;
    padding: 6px 20px;
}

.btnImg{
    margin-right: 10px;
    width: 20px;
}

.inputChange {
    background: linear-gradient(270deg, #FC877C 19.41%, #30D8A4 83.01%);
    border-radius: 8px;
    height: 20px;
    width: 70%;
    outline: none; 
    transition: background 450ms ease-in;
    margin-bottom: 20px;
    -webkit-appearance: none;
  }
  
.inputChange::-webkit-slider-thumb {
    background-color: #C82240;
    border-radius: 0;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    -webkit-appearance: none;
}

.note{
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
}
.note .lNote{
    width: 100%;
}
.note .rNote{
    box-sizing: border-box;
}

.note .rNote p{
    font-weight: 600;
}
.note .lNote p{
    font-weight: 600;
}

.note p{
    text-align: left;
}

.advice{
    padding: 0 20px;
    font-weight: 900;
}
.advice p{
    font-weight: 900;
    font-size: 14px;
}
@media(min-width:874px){
    .sloganRe{
        color: #C82240;
        font-weight: 900;
        font-size: 25px;
        margin-top: 8%;
    }
    .note{
        display: flex;
        flex-direction: row;
        padding-left: 0;
        padding-right: 0;
        font-size: 14px;
        padding-left: 27%;
        padding-top: 10%;
        position: relative;
        justify-content: space-between;
        /* margin-bottom: 15%; */
    }
    .note .lNote{
        width: 32%;
        padding-top: 24%;
        padding-left: 8%;
    }
    .note .lNote p{
        
    }
    .note .rNote{
        width: 40%;
    }
    .note .rNote p{
        
    }
    
    .note p{
        text-align: left;
    }
    .btnResult{
        padding:unset;
    }
    .btnImg{
        width: unset;
    }
    .btn{
        font-size: 20px;
        width: unset;
    }
    .resultQuiz{
        background-image: url("../../../public/page9.png");
        padding: 10px 0;
        font-size: 10px;
        background-position-x: 15%;
        background-position-y: unset;
        background-size: cover;
    }
    .re{
        margin: 10px 40px;
    }
    .inputChange{
        width: 40%;
    }
    .result{
        justify-content: center;
    }
    .advice p{
        font-size: 16px;
    }
    
}

@media(min-width:1020px){
    .note{
        font-size: 16px;
    }
    .note .rNote{
        padding-right: 12%;
        width: 44%;
    }
    .re{
        margin: 10px 60px;
        font-size: 18px;
    }
    .advice p{
        font-size: 20px;
    }
}

@media(min-width:1350px){
    .note{
        font-size: 20px;
    }
    .note .rNote{
        padding-right: 20%;
        width: 55%;
    }
    .note .lNote{
        padding-top: 10%;
        width: 17%;
        padding-left: 0;
    }
    .re{
        margin: 10px 100px;
        font-size: 20px;
    }
}

/* @media(min-width: 768px){
    .re{
        margin: 10px 40px;
    }
    .inputChange{
        width: 40%;
    }
}
@media(min-width: 992px){
    .inputChange{
        width: 40%;
    }
    .note{
        display: flex;
        flex-direction: row;
        font-size: 18px;
        padding-left: 410px;
        padding-top: 0;
        margin-bottom: 150px;
    }
    .note .lNote{
        width:30%;
        padding-top: 24%;
    }
    .note .lNote p{
       padding-right: 60px;
    }
    .note .rNote{
        width:70%;
        padding-top: 14%;
    }
    .note .rNote p{
        padding-left: 150px;
    }
    
    .note p{
        text-align: left;
        font-weight: 900;
    }
    .btnResult{
        padding:unset;
    }
    .btnImg{
        width: unset;
    }
    .btn{
        font-size: 20px;
        width: unset;
    }
    .resultQuiz{
        padding: 10px 0;
        font-size: 16px;
        background-position-x: unset;
        background-position-y: unset;
        background-size: cover;
    }
    .re{
        margin: 10px 80px;
    }
} */