.startQuiz{
    display: -webkit-flex;
    display: flex;
    width: 100%;
    font-size: 25px;
    flex-direction: column;
    -webkit-flex-direction: column;
    height: 100vh;
}
.language{
    display: flex;
    justify-content: end;
}

.language select{
    border: unset;
    font-size: 16px;
}

.rightQuiz{
    padding: 50px 40px;
}
.imgStart{
    width: 100%;
    height: 100%;
}
@media(min-width: 576px){
    .startQuiz{
    font-size: 30px;
    flex-direction: row;
    -webkit-flex-direction: row;
    }
    .rightQuiz{
        width: 50%;
    }
    .leftQuiz{
        width: 50%;
    }
}

@media(min-width: 768px){
    .startQuiz{
        font-size: 40px;
    }
}

