


.required{
  font-size: 16px;
  color: #f40505;
}
.label{
    display: flex;
}
.tiLabel{
  margin-bottom: 0;
  margin-top: 0;
}
.reset{
  padding: 2px 10px;
  border-radius: 20px;
  background: #D9D9D9;
  border: unset;
  margin-left: 10px;
}

.value{
  border-radius: 6px;
  border: 1px solid #000;
  width: 30px;
  height: 30px;
  text-align: center;
  margin-left: 30px;
  font-size: 16px;
  @media(min-width: 576px){
    width: 41px;
    height: 41px;
    font-size: 20px;
    padding-left: 10px;
    text-align: center;  
  }
}
.rangeBtn{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn{
  width: 70px;
}


.inputChange[type=range] {
  height: 20px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
.inputChange[type=range]:focus {
  outline: none;
}
.inputChange[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 1px #000000;
  background: #D9D9D9;
  border-radius: 19px;
  border: 0px solid #000000;
  /* @media(min-width: 576px){
    height: 10px;
  } */
}
.inputChange[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 25px;
  width: 25px;
  border-radius: 50px;
  background: #000000;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -10px;
  /* @media(min-width: 576px){
    height: 30px;
    width: 30px;
    margin-top: -10px;
  } */
}
.inputChange[type=range]:focus::-webkit-slider-runnable-track {
  background: #D9D9D9;
}
.inputChange[type=range]::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 1px #000000;
  background: #D9D9D9;
  border-radius: 19px;
  border: 0px solid #000000;
}
.inputChange[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  background: #000000;
  cursor: pointer;
  @media(min-width: 576px){
    height: 30px;
    width: 30px;
    /* margin-top: -10px; */
  }
}
.inputChange[type=range]::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.inputChange[type=range]::-ms-fill-lower {
  background: #D9D9D9;
  border: 0px solid #000000;
  border-radius: 38px;
  box-shadow: 0px 0px 1px #000000;
}
.inputChange[type=range]::-ms-fill-upper {
  background: #D9D9D9;
  border: 0px solid #000000;
  border-radius: 38px;
  box-shadow: 0px 0px 1px #000000;
}
.inputChange[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  background: #000000;
  cursor: pointer;
  @media(min-width: 576px){
    height: 30px;
    width: 30px;
    /* margin-top: -10px; */
  }
}
.inputChange[type=range]:focus::-ms-fill-lower {
  background: #D9D9D9;
}
.inputChange[type=range]:focus::-ms-fill-upper {
  background: #D9D9D9;
}


@media(min-width: 576px){
  .inputChange[type=range]::-ms-thumb{
    height: 30px;
    width: 30px;
    /* margin-top: -10px; */
  }
  .inputChange[type=range]::-moz-range-thumb{
    height: 30px;
    width: 30px;
  }
  .value{
    width: 60px;
    height: 41px;
    font-size: 20px;
    padding-left: 10px;
    text-align: center; 
  }
}