.quiz{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-size: 14px;
}
.leftQuiz{
    width: 100%;
    height: 100%;
}
.note{
    padding: 0 20px;
}
.imgL{
    width: 100%;
    height: 100%;
}
.rightQuiz{
    width: 100%;
    padding: 10px 20px;
    
}
.btn{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap-reverse;
}
.activeQuestion{
    display: flex;
}
.question{
    margin-top: 10px;
    margin-bottom: 10px;
}
.title{
    margin-top: 10px;
    margin-bottom: 10px;
}
.unit{
    font-weight: 900;
    position: absolute;
    right: 20px;
    font-size: 10px;
}
@media(min-width: 576px){
    .quiz{
        font-size: 20px;
    }
    .unit{
        font-size: 10px;
    }
    
}
@media(min-width: 992px){
    .quiz{
        flex-wrap: nowrap;
    }
    .leftQuiz{
        width: 50%;
    }
    .rightQuiz{
        width: 50%;
        padding: 10px 80px;
    }
    .cancelBtn{
        margin-right: 20px;
    }
    .unit{
        right: 80px;
    }
}
