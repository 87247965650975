.button{
    width: 100%;
    padding: 6px 40px;
    margin-bottom: 20px;
    font-size: 24px;
    border-radius: 6px;
    border: 1px solid rgb(135, 133, 132);
    font-family: Montserrat;
    text-transform: uppercase;
    font-weight: 400;
    cursor: pointer;
}
@media(min-width: 992px){
    .button{
        width: unset; 
    }
}
